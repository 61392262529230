import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Impressum" mdxType="SEO" />
    <p><a parentName="p" {...{
        "href": "http://www.musicartsgraz.at",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`www.musicartsgraz.at`}</a>{`
Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz`}</p>
    <p>{`MusicArtsGraz - Irmtraud Maria Eberle-Härtl`}</p>
    <p>{`Geschäftsführerin: Irmtraud Eberle-Härtl`}</p>
    <p>{`Unternehmensgegenstand: Künstleragentur
UID-Nummer: ATU 68333902`}</p>
    <p>{`Kontaktdaten
Frankensteingasse 7, A-8047 Graz
Tel.: +43 676 3412166
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:office@musicartsgraz.at",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`office@musicartsgraz.at`}</a></p>
    <p>{`Mitglied bei: WKÖ`}</p>
    <h1 {...{
      "id": "informationspflicht-gemäß-ecg"
    }}>{`Informationspflicht gemäß ECG`}</h1>
    <h1 {...{
      "id": "haftungsausschluss"
    }}>{`Haftungsausschluss`}</h1>
    <p>{`Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Webseite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.`}</p>
    <h2 {...{
      "id": "haftung-für-inhalte-dieser-webseite"
    }}>{`Haftung für Inhalte dieser Webseite`}</h2>
    <p>{`Die Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte übernehmen wir jedoch keine Gewähr. Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Wir sind jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.`}</p>
    <h2 {...{
      "id": "haftung-für-links-auf-webseiten-dritter"
    }}>{`Haftung für Links auf Webseiten Dritter`}</h2>
    <p>{`Unser Angebot enthält Links zu externen Websites. Auf den Inhalt dieser externen Webseiten haben wir keinerlei Einfluss. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.`}</p>
    <h2 {...{
      "id": "urheberrecht"
    }}>{`Urheberrecht`}</h2>
    <p>{`Die Betreiber dieser Webseite sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen. Die durch die Seitenbetreiber erstellten Inhalte und Werke auf dieser Webseite unterliegen dem Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.`}</p>
    <h1 {...{
      "id": "datenschutzerklärung"
    }}>{`Datenschutzerklärung`}</h1>
    <p>{`Wir legen großen Wert auf den Schutz Ihrer Daten. Um Sie in vollem Umfang über die Verwendung personenbezogener Daten zu informieren, bitten wir Sie die folgenden Datenschutzhinweise zur Kenntnis zu nehmen.`}</p>
    <h2 {...{
      "id": "persönliche-daten"
    }}>{`Persönliche Daten`}</h2>
    <p>{`Persönliche Daten, die Sie auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben, werden von uns nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben. Der Provider erhebt und speichert automatisch Informationen am Webserver wie verwendeter Browser, Betriebssystem, Verweisseite, IP-Adresse, Uhrzeit des Zugriffs usw. Diese Daten können ohne Prüfung weiterer Datenquellen keinen bestimmten Personen zugeordnet werden und wir werten diese Daten auch nicht weiter aus solange keine rechtswidrige Nutzung unserer Webseite vorliegt.`}</p>
    <h2 {...{
      "id": "formulardaten-und-kommentare"
    }}>{`Formulardaten und Kommentare`}</h2>
    <p>{`Wenn Webseitenbesucher Kommentare oder Formulareinträge hinterlassen, werden die eingegebenen Daten und ihre IP-Adressen gespeichert. Das erfolgt zur Sicherheit, falls jemand widerrechtliche Inhalte verfasst (Beleidigungen, links- oder rechtsextreme Propaganda, Hasspostings usw.). In diesem Fall sind wir an der Identität des Verfassers interessiert.`}</p>
    <h2 {...{
      "id": "cookies"
    }}>{`Cookies`}</h2>
    <p>{`Cookies sind kleine Dateien, die es dieser Webseite ermöglichen auf dem Computer des Besuchers spezifische, auf den Nutzer bezogene Informationen zu speichern, während unsere Website besucht wird. Cookies helfen uns dabei, die Nutzungshäufigkeit und die Anzahl der Nutzer unserer Internetseiten zu ermitteln, sowie unsere Angebote für Sie komfortabel und effizient zu gestalten. Wir verwenden einerseits Session-Cookies, die ausschließlich für die Dauer Ihrer Nutzung unserer Website zwischengespeichert werden und zum anderen permanente Cookies, um Informationen über Besucher festzuhalten, die wiederholt auf unsere Website zugreifen. Der Zweck des Einsatzes dieser Cookies besteht darin, eine optimale Benutzerführung anbieten zu können sowie Besucher wiederzuerkennen und bei wiederholter Nutzung eine möglichst attraktive Website und interessante Inhalte präsentieren zu können. Der Inhalt eines permanenten Cookies beschränkt sich auf eine Identifikationsnummer. Name, IP-Adresse usw. werden nicht gespeichert. Eine Einzelprofilbildung über Ihr Nutzungsverhalten findet nicht statt. Eine Nutzung unserer Angebote ist auch ohne Cookies möglich. Sie können in Ihrem Browser das Speichern von Cookies deaktivieren, auf bestimmte Webseiten beschränken oder Ihren Webbrowser (Chrome, IE, Firefox,…) so einstellen, dass er sie benachrichtigt, sobald ein Cookie gesendet wird. Sie können Cookies auch jederzeit von der Festplatte ihres PC löschen. Bitte beachten Sie aber, dass Sie in diesem Fall mit einer eingeschränkten Darstellung der Seite und mit einer eingeschränkten Benutzerführung rechnen müssen.`}</p>
    <h2 {...{
      "id": "google-maps"
    }}>{`Google Maps`}</h2>
    <p>{`Diese Webseite verwendet Google Maps für die Darstellung von Karteninformationen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Maps-Funktionen durch Besucher der Webseiten erhoben, verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie den Datenschutzhinweisen von Google auf `}<a parentName="p" {...{
        "href": "https://www.google.at/intl/de/policies/privacy/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://www.google.at/intl/de/policies/privacy/`}</a>{` entnehmen. Dort können Sie im Datenschutzcenter auch Ihre Einstellungen verändern, so dass Sie Ihre Daten verwalten und schützen können.`}</p>
    <h2 {...{
      "id": "datenschutzerklärung-für-die-nutzung-von-facebook"
    }}>{`Datenschutzerklärung für die Nutzung von Facebook`}</h2>
    <p>{`Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier: `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/plugins/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://developers.facebook.com/docs/plugins/`}</a>{`.
Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von facebook unter `}<a parentName="p" {...{
        "href": "https://www.facebook.com/policy.php",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://www.facebook.com/policy.php`}</a>{` Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook- Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.`}</p>
    <h2 {...{
      "id": "datenschutzerklärung-für-die-nutzung-von-google"
    }}>{`Datenschutzerklärung für die Nutzung von Google+`}</h2>
    <p>{`Mithilfe der Google +1-Schaltfläche können Sie Informationen weltweit veröffentlichen. über die Google +1-Schaltfläche erhalten Sie und andere Nutzer personalisierte Inhalte von Google und unseren Partnern. Google speichert sowohl die Information, dass Sie für einen Inhalt +1 gegeben haben, als auch Informationen über die Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf Websites und Anzeigen im Internet eingeblendet werden.
Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie und andere zu verbessern. Um die Google +1- Schaltfläche verwenden zu können, benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil, das zumindest den für das Profil gewählten Namen enthalten muss. Dieser Name wird in allen Google- Diensten verwendet. In manchen Fällen kann dieser Name auch einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten über Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils kann Nutzern angezeigt werden, die Ihre E-Mail-Adresse kennen oder über andere identifizierende Informationen von Ihnen verfügen.
Neben den oben erläuterten Verwendungszwecken werden die von Ihnen bereitgestellten Informationen gemäß den geltenden Google-Datenschutzbestimmungen genutzt. Google veröffentlicht möglicherweise zusammengefasste Statistiken über die +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie etwa Publisher, Inserenten oder verbundene Websites.`}</p>
    <h2 {...{
      "id": "datenschutzerklärung-für-die-nutzung-von-youtube"
    }}>{`Datenschutzerklärung für die Nutzung von YouTube`}</h2>
    <p>{`Auf unserer Webseite sind Funktionen des Dienstes YouTube implementiert. Diese Funktionen werden durch die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA angeboten. Die eingebundenen Videos legen bei dem Aufrufen der Webseite Cookies auf den Computern der User ab. Wer das Setzen von Cookies für das Google-Werbeprogramm deaktiviert hat, wird auch beim Aufrufen von YouTube-Videos mit keinen solchen Cookies rechnen müssen. YouTube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, müssen Sie das im Browser blockieren.`}</p>
    <h2 {...{
      "id": "auskunftsrecht"
    }}>{`Auskunftsrecht`}</h2>
    <p>{`Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer Person gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck der Speicherung.`}</p>
    <h2 {...{
      "id": "bildnachweise"
    }}>{`Bildnachweise`}</h2>
    <p>{`Peter Purgar, Irma Servatius, MusicArtsGraz, Damiano Damiani`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      